import { FC, memo, useRef, useState } from "react";
import Select from "react-select";
import type { MovieSelectorProps } from "./MovieSelector.type";

const MovieSelector: FC<MovieSelectorProps> = ({ onChange }) => {
  const statusOps = useRef([
    {
      value: "all",
      label: "All status",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "pending",
      label: "Pending",
    },
  ]).current;
  const [selectedStatus, setSelectedStatus] = useState(statusOps[0]);

  const handleChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
    onChange(selectedOption.value);
  };

  return (
    <div className="flex flex-row px-4 sm:px-0 items-center gap-8 mt-14">
      <Select
        isSearchable={false}
        value={selectedStatus}
        onChange={handleChange}
        options={statusOps}
        className="react-select react-select-carbon-light w-[200px]"
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default memo(MovieSelector);
