import MovieItem from "@components/MovieItem/MovieItem";
import MovieSelector from "@components/MovieSelector/MovieSelector";
import { FC, useEffect, useState } from "react";
import { FilmsGridProps } from "./FilmsGrid.types";

export type FilmsGridFilters = "all" | "approved" | "pending";

export const FilmsGrid: FC<FilmsGridProps> = ({ films }) => {
  const [filter, setFilter] = useState<FilmsGridFilters>("all");
  const [filteredFilms, setFilteredFilms] = useState(films);

  useEffect(() => {
    if (filter === "all") {
      setFilteredFilms(films);
    } else if (filter === "approved") {
      setFilteredFilms(films.filter((film) => film.status === "Done"));
    } else if (filter === "pending") {
      setFilteredFilms(films.filter((film) => film.status === "Review"));
    } else if (filter === "archived") {
      setFilteredFilms(films.filter((film) => film.status === "Archived"));
    }
  }, [filter]);

  useEffect(() => {
    if (films.length) {
      setFilteredFilms(films);
    }
  }, [films]);

  return (
    <>
      <h2 className="text-xl px-4 sm:px-0 text-bold text-popflick-yellow mt-12">
        Your Uploaded Films
      </h2>
      <MovieSelector onChange={setFilter} />
      <div className="flex flex-wrap flex-row sm:px-0 px-4 mb-32">
        {filteredFilms.length ? (
          filteredFilms.map((movie) => (
            <MovieItem key={movie.slug} movie={movie} />
          ))
        ) : (
          <>
            <h2 className="text-xl text-bold text-white mt-12">
              No films found
            </h2>
          </>
        )}
      </div>
    </>
  );
};
