import { useCallback, useEffect, useMemo, useState } from "react";
import Head from "next/head";
import { Chart, Table } from "../components";
import DateRangeSelect from "../components/daterange/DateRange";
import styles from "../styles/Home.module.scss";
import { config } from "../config";
import { getFirstDayOfNextMonth, isCurrentMonth } from "../utils";
import { CSVLink } from "react-csv";
import classNames from "classnames";
import Link from "next/link";
import format from "date-fns/format";
import { useContributorMovies } from "hooks/useContributorMovies";
import { useUser } from "hooks/useUser";
import { useRouter } from "next/router";
import { FilmsGrid } from "@components/FilmsGrid/FIlmsGrid";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const [viewsData, setViewsData] = useState<any>({});
  const [totalViews, setTotalViews] = useState<any>([0]);
  const [totalWatch, setTotalWatch] = useState<any>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableMonthsData, setTableMonthsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [range, setRange] = useState<{
    initDate: string;
    endDate: string;
    contributor: any;
  }>();
  const [initDate, setInitDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const contributorMovies = useContributorMovies();
  const isAdmin = useMemo(
    () => user?.groups.some((group: string) => group === "Admins"),
    [user?.groups]
  );

  const router = useRouter();
  const { slug } = router.query;

  const CHART_DATA = {
    labels: viewsData["chartLabels"],
    datasets: [
      {
        label: "Views",
        data: viewsData["chartData"],
      },
    ],
  };

  const TABLE_COLUMNS = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title", // accessor is the "key" in the data
        Cell: ({ value, row }) => (
          <Link
            href={{
              pathname: `/film-manager/${row.original._slug}/views`,
              query: {
                ini: `${
                  row.original.views > 0 ? initDate : row.original.uploadDate
                }`,
                end: `${
                  row.original.views > 0
                    ? endDate
                    : format(Date.now(), "yyyy-MM-dd")
                }`,
              },
            }}
          >
            <a className={`hover:underline`}>
              <span>{value}</span>
            </a>
          </Link>
        ),
      },
      {
        Header: "Contributor email",
        accessor: "contributorEmail",
      },
      {
        Header: "Upload date",
        accessor: "uploadDate",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => (
          <label
            className={classNames({
              "text-success": value === "Published",
              "text-warning": value === "Review",
            })}
          >
            {value}
          </label>
        ),
      },
      {
        Header: "Minutes",
        accessor: "minutes",
      },
      {
        Header: "Views",
        accessor: "views",
        Cell: ({ value, row }) => {
          return (
            <Link
              href={{
                pathname: `/film-manager/${row.original._slug}/views`,
                query: {
                  ini: `${
                    row.original.views > 0 ? initDate : row.original.uploadDate
                  }`,
                  end: `${
                    row.original.views > 0
                      ? endDate
                      : format(Date.now(), "yyyy-MM-dd")
                  }`,
                },
              }}
            >
              <a className={"hover:underline"}>
                <span>{value}</span>
              </a>
            </Link>
          );
        },
      },
    ],
    [endDate, initDate]
  );

  useEffect(() => {
    if (range) {
      setInitDate(range.initDate);
      setEndDate(range.endDate);
    }
  }, [range]);

  const updateRange = (initDate: any, endDate: any, contributor: any): any => {
    setRange({ initDate, endDate, contributor });
  };

  const getViewsDataInfo = useCallback(
    async ({
      initDate,
      endDate,
      contributor,
    }: {
      initDate: string;
      endDate: string;
      contributor: any;
    }) => {
      try {
        setLoading(true);
        const body = JSON.stringify({
          iniDate: initDate,
          endDate: endDate,
          slug,
          contributor,
        });
        const url = slug ? "/api/mux/views/detail" : "/api/mux/views/list";
        const getViewsData = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });

        const viewsData = await getViewsData.json();

        let totalViewsData: any = {};
        let totalWatchTime: any = 0;

        if (viewsData.result === "success") {
          const data = viewsData.data;
          const info = data.info;
          totalWatchTime = (data.total_watch_time.total / 3600000).toFixed(2);
          let dataInfo = [];

          if (isAdmin) {
            for (let key in info) {
              if (info.hasOwnProperty(key)) {
                const obj = info[key];
                const slug = key.split("|")[0];
                const minutes = (obj["watch_time"] / 60000).toFixed(2);

                const tableReg = {
                  _slug: slug,
                  title: obj["title"],
                  contributorEmail: obj["contributorEmail"],
                  uploadDate: obj["uploadDate"].split("T")[0],
                  status: obj["status"],
                  minutes: minutes,
                  amount: obj["amount"].toFixed(2),
                  views: obj["views"],
                };

                if (
                  !user.groups.includes("Admins") &&
                  isCurrentMonth(initDate)
                ) {
                  tableReg.amount =
                    "Available on " +
                    getFirstDayOfNextMonth().toISOString().split("T")[0];
                }

                dataInfo.push(tableReg);
              }
            }
            setTableData(dataInfo);
          } else if (data.quarters_info !== undefined) {
            let monthsInfo = [];

            for (let key in data.months_info) {
              if (data.months_info.hasOwnProperty(key)) {
                const obj = data.months_info[key];
                const tableReg = {
                  year: obj["year"],
                  month: obj["monthName"],
                  minutes: (obj["watch_time"] / 60000).toFixed(2),
                  views: obj["views"]
                };
                monthsInfo.push(tableReg);
              }
            }

            setTableMonthsData(monthsInfo);
          }

          const dataViewsChart = data.views;

          let chartLabels = [];
          let chartData = [];
          let viewsCount = 0;

          for (let key in dataViewsChart) {
            if (dataViewsChart.hasOwnProperty(key)) {
              const obj = dataViewsChart[key];
              chartLabels.push(obj["day"]);
              chartData.push(obj["views"]);
              viewsCount += parseInt(obj["views"]);
            }
          }

          totalViewsData["chartLabels"] = chartLabels;
          totalViewsData["chartData"] = chartData;
          setTotalViews(viewsCount);
        }

        setViewsData(totalViewsData);
        setTotalWatch(totalWatchTime);
      } catch (error: any) {
        console.log("ERROR", error.message);
      } finally {
        setLoading(false);
      }
    },
    [user, isAdmin, slug]
  );

  useEffect(() => {
    if (user && typeof range !== "undefined") {
      getViewsDataInfo(range);
    }
  }, [user, range]);

  const TABLE_MONTH_COLUMNS = [
    {
      Header: "Year",
      accessor: "year",
    },
    {
      Header: "Month",
      accessor: "month",
    },
    {
      Header: "Minutes",
      accessor: "minutes",
    },
    {
      Header: "Views",
      accessor: "views",
    },
  ];

  return (
    <>
      <Head>
        <title>Popflick - Admin</title>
        <meta name="description" content="Generated by create next app" />
        <link
          rel="icon"
          href={`${config.staticFileBaseUrl}/images/favicon.ico`}
        />
      </Head>

      <main className={styles.main}>
        <div className="flex flex-1 flex-column">
          {!isAdmin && (
            <>
              <h1 className="text-2xl text-bold px-4">
                Welcome {user?.first_name}! Thank you for working with us.
              </h1>
              {!user?.hide_statistics && (
                <h2 className="text-xl px-4 text-bold text-popflick-yellow mt-12">
                  Your Film Statistics
                </h2>
              )}
            </>
          )}
          {!user?.hide_statistics && (
            <>
              <div className="mt-5 ml-4">
                <DateRangeSelect dataUpdaterHandler={updateRange} />
              </div>
              <div className={`my-4`}>
                <Chart
                  title={
                    isAdmin
                      ? `Total views: ${totalViews} | Total Hours: ${totalWatch}`
                      : `Total views: ${totalViews} | Total Hours: ${totalWatch}`
                  }
                  data={CHART_DATA}
                  loading={loading}
                />
              </div>
            </>
          )}
          {isAdmin ? (
            <>
              <div className={`flex justify-end my-4 mr-4`}>
                <CSVLink className={`btn btn-yellow`} data={tableData}>
                  Download
                </CSVLink>
              </div>
              <div className="w-screen sm:w-full overflow-x-scroll px-4">
                <Table
                  title="Movies"
                  columns={TABLE_COLUMNS}
                  data={
                    filteredData && filteredData.length /*|| searchInputVal*/
                      ? filteredData
                      : tableData
                  }
                  range={range}
                />
              </div>
            </>
          ) : (
            <>
              {!user?.hide_statistics && (
                <>
                  <Table
                    title="MonthEarnings"
                    columns={TABLE_MONTH_COLUMNS}
                    data={tableMonthsData}
                    range={range}
                  />
                </>
              )}
              <FilmsGrid films={contributorMovies} />
            </>
          )}
        </div>

        {/* {!isAdmin ? (
            <div className="hidden md:block">
              <FilmMakerNews />
            </div>
          ) : (
            <></>
          )} */}
      </main>
    </>
  );
}
